<template>
  <div class="w course-box">
    <div class="inputbox">
      <el-input
        placeholder="请输入您要搜索的课程"
        v-model="input3"
        class="input-with-select"
      >
        <template slot="prepend"
          ><img class="searchimg" src="./images/search.png" alt=""
        /></template>
        <el-button slot="append">搜索</el-button>
      </el-input>
    </div>
    <div class="classlist">
      <ul>
        <!-- <li class="active">全部</li> -->
        <li
          :class="activeSubject == sub.id ? 'active' : ''"
          v-for="sub in subjectList"
          :key="sub.id"
          @click="changeSubject(sub.id)"
        >
          {{ sub.subjectName }}
        </li>
      </ul>
      <div class="history" @click="gohistory">历史记录</div>
    </div>
    <div
      class="courselist"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(255,255,255,0.6)"
    >
      <div
        class="courseitem"
        v-for="albums in albumList"
        :key="albums.id"
        @click="
          courseDetail(albums.id, albums.albumName, albums.subjectId, albums)
        "
      >
        <img :src="albums.coverId" alt="" />
        <div class="collect">共{{ albums.total }}集</div>
        <div class="oneline">
          <div class="taglist">
            <!-- <div class="tag-item tag_four">专四</div> -->
            <!-- <div v-if="albums.isVip == 'N'" class="tag-item tag_free">免费</div> -->
            <div v-if="albums.isTop == 'Y'" class="tag-item tag_free">推荐</div>
            <div v-if="albums.isNew == 'Y'" class="tag-item tag_new">NEW</div>
          </div>
          <div class="studynum">
            <span>{{ albums.favorNum }}人已学过</span>
          </div>
        </div>
        <div class="twoline">
          <p>{{ albums.albumName }}</p>
        </div>
      </div>
      <!-- <div class="courseitem">
        <img src="./images/course.png" alt="" />
        <div class="collect">共5集</div>
        <div class="oneline">
          <div class="taglist">
            <div class="tag-item tag_four">专四</div>
            <div class="tag-item tag_free">免费</div>
          </div>
          <div class="studynum"><span>1368人已学过</span></div>
        </div>
        <div class="twoline">
          <p>2023年专八真题及答案</p>
        </div>
      </div>
      -->
    </div>
    <el-pagination
      :page-size="albumParam.pageSize"
      :current-page="albumParam.pageNum"
      hide-on-single-page
      background
      layout="prev, pager, next"
      :total="albumTotal"
      prev-text="上一页"
      next-text="下一页"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  subjectTree, albumList, pdfList
} from "@/api"
export default {

  name: "Course",
  data () {
    return {
      loading: true,
      input3: '',
      subjectList: [],
      albumParam: {
        pageNum: 1,
        pageSize: 10,
        albumName: '',
        subjectId: '',
        // isTop: 'N',
        // isRecomm: 'N'
      },
      albumList: [],
      albumTotal: 0,
      activeSubject: 0
    }
  },
  mounted () {
    this.getSubjectTree()

  },
  methods: {
    changePage (page) {
      this.albumParam.pageNum = page
      this.getAlbumList()

    },
    gohistory () {
      this.$router.push({ path: '/history' })
    },
    getSubjectTree () {
      subjectTree({ subjectType: 'class' }).then((d) => {
        if (d.code == 200) {
          this.subjectList = d.data
          this.activeSubject = this.subjectList[0].id
          this.getAlbumList()
        }

      })

    },
    getAlbumList () {
      this.albumParam.subjectId = this.activeSubject
      albumList(this.albumParam).then((d) => {
        if (d.code == 200) {
          // console.log(d, 'xxx')
          this.loading = false
          this.albumList = d.rows
          this.albumTotal = d.total
        }

      })

    },
    changeSubject (id) {
      this.albumParam.pageNum = 1
      this.activeSubject = id
      this.getAlbumList()
    },
    courseDetail (id, albumName, subjectId, albums) {
      // 
      this.$router.push({ name: 'CourseDetail', query: { albumId: id, albumName, subjectId }, params: { albums } })
    }

  }

}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
.course-box {
  padding: 130px 0 80px;
  .inputbox {
    text-align: center;
  }
  .el-input {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .el-input-group {
    max-width: 744px;
    width: 80%;
    height: 60px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.6);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(20px);
  }
  /deep/.el-input-group__append {
    border: none;
    width: 111px;
    height: 52px;
    border-radius: 12px;
    background: rgba(67, 207, 124, 1);
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  /deep/.el-input-group__prepend {
    border: none;
    background-color: transparent;
  }
  /deep/.el-input__inner {
    height: 100%;
    border: none;

    font-size: 20px;
    font-weight: 400;
    color: rgba(29, 29, 31, 1);

    // color: rgba(170, 170, 170, 1);
  }
  .classlist {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 48px;
      padding: 0 10px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.6);
      border: 2px solid rgba(255, 255, 255, 1);
      backdrop-filter: blur(20px);
      li {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(119, 119, 119, 1);
        text-align: center;
        padding: 5px 10px;
        margin-right: 8px;
        cursor: pointer;
      }
      .active {
        // margin-right: 18px;
        color: rgba(29, 29, 31, 1);
        background: rgba(255, 255, 255, 1);
      }
    }
    .history {
      width: 80px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid rgba(67, 207, 124, 1);
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: rgba(67, 207, 124, 1);
      text-align: center;
      line-height: 32px;
    }
  }
  .courselist {
    width: 100%;
    margin-top: 20px;
    min-height: 72vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // justify-content: space-between;
    .courseitem {
      width: 284px;
      height: 250px;
      cursor: pointer;
      // margin-right: 18px;
      // margin-top: 40px;
      margin: 20px 8px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(20px);
      border: 2px solid rgba(255, 255, 255, 1);
      position: relative;
      img {
        width: 284px;
        height: 160px;
        position: absolute;
        top: -2px;
        left: -2px;
      }
      // .coursedes {

      //   border-top: none;
      // }
      .collect {
        position: absolute;
        right: 16px;
        top: 125px;
        height: 24px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        padding: 1px 9px 1px 9px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
      .oneline {
        margin-top: 170px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        .taglist {
          display: flex;

          .tag-item {
            width: 40px;
            height: 24px;
            border-radius: 4px;
            margin-right: 10px;

            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 24px;
          }
          .tag_four {
            background: linear-gradient(
              135deg,
              rgba(255, 138, 20, 1) 0%,
              rgba(254, 197, 101, 1) 100%
            );
          }
          .tag_new {
            background: linear-gradient(
              90deg,
              rgba(76, 224, 215, 1) 0%,
              rgba(81, 159, 232, 1) 100%
            );
          }
          .tag_vip {
            background: linear-gradient(
              90deg,
              rgba(224, 76, 76, 1) 0%,
              rgba(232, 161, 82, 1) 100%
            );
          }
          .tag_free {
            background: linear-gradient(
              90deg,
              rgba(224, 76, 76, 1) 0%,
              rgba(232, 161, 82, 1) 100%
            );
          }
        }
        .studynum {
          font-size: 16px;
          font-weight: 400;
          color: rgba(170, 170, 170, 1);
        }
      }
      .twoline {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        margin-top: 14px;
        padding: 0 16px;
        overflow: hidden;
        white-space: nowrap;

        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }
  /deep/.el-pagination {
    text-align: center;
    margin: 20px 0 40px;
  }
  /deep/.el-pagination.is-background .btn-next,
  /deep/.el-pagination.is-background .btn-prev,
  /deep/.el-pagination.is-background .el-pager li {
    height: 32px;
    line-height: 32px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(255, 255, 255, 1);
    backdrop-filter: blur(20px);

    font-size: 14px;
    font-weight: 400;
    color: rgba(119, 119, 119, 1);

    padding: 0 12px;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    height: 32px;
    background: rgba(67, 207, 124, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
  }
}
</style>